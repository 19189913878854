// 申遗承诺
import SHZT from './sn-table-column-shzt';

const selfData = {
    loading: false,
    exp: true,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [
                {
                    prop: 'CNSXLYLX',
                    label: '承诺事项来源类型',
                    minWidth: 135
                }, {
                    prop: 'CNLY',
                    label: '承诺事项来源',
                    minWidth: 200,
                    showoverflowtooltip: true
                }, {
                    prop: 'CNWJSJ',
                    label: '采纳文件时间',
                    minWidth: 128,
                }, {
                    prop: 'CNSXNR_ZW',
                    label: '承诺事项内容（中文）',
                    minWidth: 160,
                    showoverflowtooltip: true
                }, {
                    prop: 'CNWCSJ',
                    label: '承诺完成时间',
                    minWidth: 130,
                    sortable: 'custom'
                },
                SHZT,
            ],
            // 操作列
            columnsBtn: {
                buttonData: []
            }
        },
        // 操作列
        columnsBtn: {
            buttonData: [
            ]
        }
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
        selectData: [{
            label: '承诺事项来源类型',
            clearable: true,
            placeholder: "请选择承诺事项来源类型",
            list: [{
                ID: '大会决议',
                NAME: '大会决议'
            }, {
                ID: '申遗文本',
                NAME: '申遗文本'
            }, {
                ID: '申遗补充材料',
                NAME: '申遗补充材料'
            }],
            optionLabel: 'NAME',
            optionValue: 'ID',
            value: 'CNSXLYLX',
            operateType: 'search',
            isShow: true,
            width: '184px'
        }],
    },
    snButton: {
        buttonData: [{
            isShow: true,
            btnType: 'button',
            operateType: 'buttonExport',
            showMethod: 'showExportBtn',
            name: '导出',
            round: true,
            backColor: '#ffcc66',
            color: '#fff'
        }]
    }
};
export default selfData;